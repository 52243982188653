import React, { useEffect, useState } from "react";
import styles from "./IntroSection.module.css";
import quem_somos from "../../assets/quem_somos.png";

interface IntroSectionProps {
    children?: React.ReactElement;
}

const IntroSection: React.FC<IntroSectionProps> = () => {

    const [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const intro_s = document.getElementById("quem_somos") as HTMLElement;

        const h = intro_s?.offsetTop as number;

        document.addEventListener('scroll', () => {
            if (!(window.scrollY < h - 600)) {
                setVisible(true);
            }
        })
    })

    return (
        <div className={styles.intro_section} id="quem_somos">
            <div className={styles.quem_somos}><img src={quem_somos} className={isVisible ? styles.start : styles.none} /></div>

            <div className={styles.description}>
                <div className={isVisible ? styles.end : styles.none}>
                    <span className={styles.yellow}><b>A Editora DoGo Maker</b></span> é uma empresa dedicada à divulgação e popularização da Educação 4.0.
                    Oferecemos livros educacionais em áreas como robótica educacional, pensamento computacional,
                    programação, desenvolvimento de games e impressão 3D, todos alinhados com a Base Nacional
                    Comum Curricular (BNCC) e os Objetivos de Desenvolvimento Sustentável (ODS) da ONU.
                </div>

            </div>
            <div className={isVisible ? styles.start : styles.none}>
                <iframe width="760" height="415" src="https://www.youtube.com/embed/sQxA7gHfsXk?si=4gET75GIFc32wNig" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" className={styles.iframe}></iframe>
            </div>
        </div>
    )
}

export default IntroSection;