import React, { RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import styles from "./Materials.module.css";
import banner from "../../assets/images/materialsBanner.png";

import materials1 from "../../assets/images/materials1.png";
import materials2 from "../../assets/images/materials2.png";
import materials3 from "../../assets/images/materials3.png";
import materials4 from "../../assets/images/materials4.png";

import bannerLivro1a5 from '../../assets/images/banner-livro-1a5.png';
import bannerLivro6a9 from '../../assets/images/banner-livro-6a9.png';
import bannerLivroNovoEM from '../../assets/images/banner-livro-EM.png';

import materialsRobotica from "../../assets/images/materialsRobotica.png";
import materialsPensamento from "../../assets/images/materialsPensamento.png";
import RoundedButton from "../../UI/RoundedButton/RoundedButton";
import BooksComponentMobile from "./Components/BooksComponentMobile";
import { IoMdClose } from "react-icons/io";

interface IMaterials {
    children?: React.ReactElement;
}



function useOutsideClick<T extends HTMLElement>(
    ref: RefObject<T>,
    callback: () => void
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {

        };
    }, [ref, callback]);
}

const Materials: React.FC<IMaterials> = () => {



    const [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const intro_s = document.getElementById("materials") as HTMLElement;
        const h = intro_s?.offsetTop as number;

        document.addEventListener('scroll', () => {
            if (!(window.scrollY < (h))) {
                setVisible(true);
            }
        })
    })



    const [bookWindowStyle, setBookWindowStyle] = useState<string>(styles.bookWindowNone);
    const [isPensamento, setPensamento] = useState<string>("saiba1");
    const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth >= 600);
    const [active, setActive] = useState<boolean>(false);

    const getWidth = (e: SyntheticEvent) => {
        const el = e.target as HTMLElement;
        setPensamento(el.id);
        setBookWindowStyle(styles.bookWindow);
        setActive(state => !state);
    }



    const wrapperRef = useRef(null);

    const wrapperRef2 = useRef(null);

    useOutsideClick(wrapperRef, () => {
        active && setBookWindowStyle(styles.bookWindowNone)
    })


    return (
        <>
            <div className={styles.materials} id="materials">

                <div className={styles.customSection}>

                    <div className={styles.banner}>
                        <img src={banner} className={isVisible ? styles.bottomtopup : styles.none} />
                    </div>


                    <div className={isVisible ? styles.uptodown : styles.none}>
                    <div className={styles.list}>
                        <div>
                            <div className={styles.el_list}>
                                <img src={materials4} />

                            </div>

                            <div className={styles.el_list}>
                                <img src={materials2} />
                            </div>
                        </div>

                        <div>

                            <div className={styles.el_list}>
                                <img src={materials3} />
                            </div>

                            <div className={styles.el_list}>
                                <img src={materials1} />
                            </div>
                        </div>


                    </div>
                    </div>

                </div>
                <div className={styles.footer}>

                <div className={styles.booksList}>
                <h2 className={styles.bookList_title}>Nossos Livros</h2>
                <img onClick={getWidth} id="saiba1" className={styles.imgBannerLivro} src={bannerLivro1a5} alt="banner de livros do primeiro ao quinto ano" />
                <img onClick={getWidth} id="saiba2" className={styles.imgBannerLivro} src={bannerLivro6a9} alt="banner de livros do sexto ao nono ano" />
                <img className={styles.imgBannerLivro} src={bannerLivroNovoEM} alt="banner de livros do novo ensino medio" />
                </div>


{/* 
                    {
                        isDesktop ? <div className={isVisible ? styles.start: styles.none}>
                            <div className={styles.icon}>
                                <img src={materialsPensamento} />
                                <div className={styles.textIcon}>
                                    <div className={styles.titleBookDescription}>
                                        INICIAÇÃO AO DESENVOLVIMENTO
                                        DO PENSAMENTO COMPUTACIONAL
                                        COM PROGRAMAÇÃO VISUAL
                                    </div>
                                    <br />
                                    <div className={styles.bookDescription}>
                                        Compreender o cenário atual da educação e sua relação com a
                                        tecnologia é fundamental para preparar os estudantes para os
                                        desafios do século 21. O livro surge como parte integrante desse
                                        movimento, oferecendo uma abordagem abrangente e prática para
                                        desenvolver o Pensamento Computacional.
                                    </div>
                                    <br />
                                    <RoundedButton onClick={getWidth} id="saiba1">
                                        SAIBA MAIS +
                                    </RoundedButton>
                                </div>

                            </div>

                            <div className={styles.icon}>
                                {<img src={materialsRobotica} />}
                                <div className={styles.textIcon}>
                                    <div className={styles.titleBookDescription}>
                                        ROBÓTICA EDUCACIONAL SUSTENTÁVEL COM PROGRAMAÇÃO VISUAL
                                    </div>
                                    <br />
                                    <div className={styles.bookDescription}>
                                        O livro tem uma abordagem inovadora e sustentável para a introdução
                                        da robótica educacional no contexto escolar. Ele se concentra em
                                        fornecer aos educadores e alunos uma compreensão sólida dos conceitos
                                        fundamentais da robótica, enquanto promove a sustentabilidade
                                        e o uso responsável dos recursos.
                                    </div>
                                    <br />
                                    <RoundedButton onClick={getWidth} id="saiba2">
                                        SAIBA MAIS +
                                    </RoundedButton>
                                </div>
                            </div>
                            </div> : <div className={isVisible ? styles.start1: styles.none}><BooksComponentMobile setPensamento={setPensamento} setBookWindowStyle={setBookWindowStyle} /></div>
                    } */}


                </div>



            </div>
            <div id="bookwindow" className={bookWindowStyle} ref={wrapperRef}>
                <div className={styles.close}>
                    <IoMdClose size={32} onClick={() => {
                        setBookWindowStyle(styles.bookWindowNone)
                    }} />
                </div>
                {/*   <div>
                    <img src={isPensamento === "saiba1" ? materialsSaiba1 : materialsSaiba2} ref={wrapperRef} />
                </div>
 */
                }
                <div className={styles.bannerContainer}>
                    { /* PARTE DA IMAGEM
                        <div className={styles.imageContainer}>
                        <img src={isPensamento === "saiba1" ? materialsPensamento : materialsRobotica} width={150} ref={wrapperRef} />
            </div> */}
                    <div className={styles.header}>
                        {isPensamento === "saiba1" ? 'EDU DIGITAL: EDUCAÇÃO TECNOLÓGICA E DIGITAL DO PENSAMENTO COMPUTACIONAL E ROBÓTICA' : 'ROBÓTICA EDUCACIONAL SUSTENTÁVEL COM PROGRAMAÇÃO VISUAL & INICIAÇÃO AO PENSAMENTO COMPUTACIONAL'}

                    </div>

                    {
                        isPensamento === "saiba1" ? <div className={styles.body}>
                            <div className={styles.leftSection}>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Edu Digital</div>
                                    <ul className={styles.list1}>
                                        <p>A Coleção EduDigital para o Ensino Fundamental Anos Iniciais é uma excelente ferramenta pedagógica para desenvolver a Educação Tecnológica e Digital nas crianças do século XXI. Com uma abordagem inovadora e criativa, os alunos aprendem sobre o Desenvolvimento do Pensamento Computacional e a Robótica Educacional Sustentável com micro:bit. Assim, desenvolvem competências e habilidades ligadas ao mundo tecnológico e à cultura digital, como programação, algoritmos, computação, criatividade, inovação e empreendedorismo. Além disso, também são trabalhadas competências socioemocionais, como foco, concentração, trabalho em equipe, compartilhamento de ideias, sustentabilidade e engajamento.
                                        </p>
                                    </ul>
                                </div>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Metodologias Ativas da Educação:</div>
                                    <ul className={styles.list1}>
                                        <p>O Projeto EduDigital adota abordagens contemporâneas, utilizando metodologias ativas na educação, como a gamificação, a educação maker e o aprendizado STEAM. Essas abordagens aumentam o engajamento e o protagonismo dos estudantes, incentivando-os a "colocar a mão na massa" e solucionar problemas reais de forma eficaz.Inovador e criativo, o Projeto EduDigital proporciona um aprendizado lúdico sobre temas contemporâneos e prepara os estudantes para o pleno exercício da cidadania no futuro.
                                        </p>
                                    </ul>
                                </div>
                            </div>
                        </div> 
                        // SAIBA2   
                        : <div className={styles.body}>
                            <div className={styles.leftSection}>
                                <div className={styles.box}>
                                    <div className={styles.title1}>ROBÓTICA EDUCACIONAL SUSTENTÁVEL COM PROGRAMAÇÃO VISUAL</div>
                                    <ul className={styles.list1}>
                                        <li>O livro tem uma abordagem inovadora e sustentável para a introdução
                                        da robótica educacional no contexto escolar. Ele se concentra em
                                        fornecer aos educadores e alunos uma compreensão sólida dos conceitos
                                        fundamentais da robótica, enquanto promove a sustentabilidade
                                        e o uso responsável dos recursos.</li>
                                    </ul>
                                </div>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Sustentabilidade na Robótica Educacional:</div>
                                    <ul className={styles.list1}>
                                        <li>Destaca a importância da sustentabilidade, apresentando métodos de construção e programação de robôs com materiais de baixo custo e alternativos.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={styles.rightSection}>
                                <div className={styles.box}>
                                    <div className={styles.title1}>INICIAÇÃO AO DESENVOLVIMENTO
                                        DO PENSAMENTO COMPUTACIONAL
                                        COM PROGRAMAÇÃO VISUAL</div>
                                    <ul className={styles.list1}>
                                        <li> Compreender o cenário atual da educação e sua relação com a
                                        tecnologia é fundamental para preparar os estudantes para os
                                        desafios do século 21. O livro surge como parte integrante desse
                                        movimento, oferecendo uma abordagem abrangente e prática para
                                        desenvolver o Pensamento Computacional.</li>
                                    </ul>
                                </div>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Integração Curricular e STEAM:</div>
                                    <ul className={styles.list1}>
                                        <li>Enfatiza a integração da robótica educacional com disciplinas como ciências, matemática, artes e tecnologia, oferecendo exemplos práticos de aplicação interdisciplinar.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }

                </div>

            </div>
        </>

    )
}

export default Materials;